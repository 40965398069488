import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import moment from "moment"

export default function Template({ data }) {
  const { markdownRemark: post } = data
  const formattedDate = moment(post.frontmatter.date).locale("hu").format("LL")
  return (
    <Layout>
      <SEO title={`${post.frontmatter.title}`} />
      <Helmet title={`${post.frontmatter.title}`} />

      <div className="blog-post-container">
        <div className="blog-post">
          <h2>{post.frontmatter.title}</h2>

          <h5>
            {post.frontmatter.author} - {formattedDate}
          </h5>

          <br/><br/>

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        author
      }
    }
  }
`
